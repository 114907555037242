.card{
      width: 32%;
      border: 0.7px solid #ffffff;
      border-radius: 1rem;
      background: #191919;
      display: flex;
      flex-direction: column;
      padding-top: 0.5rem;
      text-decoration: none;
      color: #ffffff;

      .imgBx{
        width: 95%;
        height: 50vh;
        background: grey;
        margin: 0 auto;
        border-radius: 1rem;

        img{
          width: 100%;
          border-radius: 1rem;
          height: 50vh;
          display: block;
          object-fit: cover;
        }
      }
      .textBx{
        height: 20vh;
        text-align: left;
        width: 95%;
        margin: 6px auto;
        h5{
          margin: 6px 0rem;
          text-transform: uppercase;
          font-size: clamp(16px, 1.5vw, 3rem);
          width: 100%;
        }
        .date{
          margin: 1px 0rem;
          font-size: clamp(16px, 1.5rem, 3rem);
          font-weight: 600;
           .icons{
            color: #BF0A30;
            margin: 0px;
            margin-right: 1px;
        }
        }
        .other{
          margin: 1rem 0rem;
          font-size: clamp(12px, 1rem, 2.5rem);
          font-weight: 300;
           .icons{
              color: #BF0A30;
              margin: 0px 2px;
              margin-right: 5px;
            }
        }
      }
    }

.card:active{
  color: #ffffff;
}