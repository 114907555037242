.homepage-body{
  width: 100%;
  background: #191919;
  position: relative;

  .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #191919b5;
    height: 100vh;
    padding: 5rem 0rem;
    padding-top: 10vw;
    z-index: 1;
  }

  button{
    cursor: pointer;
  }

  button:hover{
    opacity: 0.8;
  }
  .section1{
    width: 100%;
    color: white;
    text-align: center;
    padding: 5rem 0rem;
    padding-top: 10vw;
    background-image:  url('../../components/assets/images/home-page.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
    height: 100vh;
    position: relative;
    

    h2{
      font-size: clamp(24px, 4vw, 6rem);
      width: 70%;
      margin: 5rem auto; 
      text-transform: uppercase;
      z-index: 2;
      position: relative;
    }

    p{
      font-size: clamp(16px, 3vw, 2rem);
      margin: 2rem auto;
      width: 70%;
      z-index: 2;
      position: relative;

    }
    .flex{
      display: flex;
      width: 40%;
      justify-content: space-around;
      align-items: center;
      margin: 0 auto;
      position: relative;
      z-index: 2;

      button{
        display: block;
        color: white;
        text-transform: uppercase;
        padding: 1.5rem;
        font-size: clamp(16px, 1vw, 2rem);
        width: 30%;
        border-radius: 1rem;
      }

      .red{
        background: #BF0A30;
        border: none;
      }

      .blur{
        background: transparent;
        backdrop-filter: blur(3px);
        border: 1px, solid, white;
      }
    }

    .socials{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      a{
        display: block;
        margin-top: 1rem;
        margin-left: 2rem;
        color: #ffffff;
      }
    }
  }

  .service-section{
    width: 80%;
    margin: 5rem auto;
    text-align: center;
    color: #ffffff;
    position: relative;
    z-index: 2;


    h3{
      font-size: clamp(16px, 3vw, 2rem);
      margin: 0rem;
      text-transform: uppercase;
    }
    h4{
      font-size: clamp(24px, 4vw, 6rem);
      color: #BF0A30;
      margin: 0rem;
      text-transform: capitalize;
    }
    hr{
      width: 15%;
      height: 5px;
      margin: 5px auto;
      border: none;
      background-color: #ffffff;
    }
    button{
      display: flex;
      background: #BF0A30;
      color: #ffffff;
      text-transform: uppercase;
      padding: 1.3rem;
      width: 20%;
      border: none;
      font-size: clamp(16px, 1vw, 2rem);
      justify-content: center;
      border-radius: 0.625rem;
      text-align: center;
      margin: 0;
      margin-left: auto;
    }
    .flex{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 1.5rem 0rem; 
    }
  }

  .about-section{
    display: flex;
    color: #ffffff;
    width: 80%;
    margin: 0 auto;
    justify-content: space-evenly;

    .image{
      width: 47%;


      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .text{
      width: 47%;

      h3{
        font-size: clamp(16px, 1.5vw, 4rem);
        margin: 0rem;
        text-transform: uppercase;
      }
      h4{
        font-size: clamp(24px, 3vw, 6rem);
        color: #BF0A30;
        margin: 0rem;
      }
      p{
        font-size: clamp(1.2rem, 1.5rem, 3rem);
      }
    }
  }

  .event-section{
    width: 100%;
    color: #ffffff;
    background-image: url("https://images.unsplash.com/photo-1522158637959-30385a09e0da?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    padding: 4rem 0rem;
    text-align: center;

    .container{
      width: 90%;
      border-radius: 1rem;
      border: 1px white solid;
      object-fit: cover;
      backdrop-filter: blur(8px);
      margin: 0 auto;
      padding: 2rem 0rem;

      h3{
        font-size: clamp(16px, 1.5vw, 4rem);
          margin: 0rem;
          text-transform: uppercase;
        }
        h4{
          font-size: clamp(24px, 4vw, 6rem);
          color: #BF0A30;
          margin: 0rem;
          text-transform: capitalize;
        }

        .underline{
           width: 15%;
           height: 5px;
           margin: 5px auto;
           border: none;
           background-color: #ffffff;
         }
    }
    
      .event-card{
        width: 90%;
        margin: 3rem auto;
        background: none;

        .wrapper{
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;

          .red{
            width: 10%;
            background: #BF0A30;
            padding: 3rem 0rem;
            font-size: clamp(1.2rem, 1.5rem, 3rem);
            border-radius: 20px 0px 0px 20px;
          }
          .body{
            width: 89.5%;
            display: flex;
            justify-content: space-around;
            border: 0.6px solid #ffffff;
            backdrop-filter: blur(5px);
            padding: 2rem 0rem;

            .name{
              color: #BF0A30;
              font-size: clamp(16px, 1.5vw, 4rem);
              text-transform: uppercase;
              width: 30%;
              .alias{
                display: block;
                color: #ffffff;
                font-size: clamp(1rem, 1.6rem, 2rem);
                text-transform: capitalize;
              }
            }
            .misc{
              display: flex;
              justify-content: space-evenly;
              width: 70%;

              .time{
                width: 30%;
                font-size: clamp(16px, 1.5vw, 4rem);
              }
              .location{
                font-size: clamp(16px, 1.5vw, 4rem);
              }
            }

          }
        }
        .event-button{
          width: 15%;
          margin: 0.5rem;
          display: flex;
          margin-left: auto;
          padding: 1rem;
          justify-content: center;
          border-radius: 1rem;
          border: none;
          background: #BF0A30;
          color: #ffffff;
          font-size: clamp(16px, 1vw, 2rem);
          text-transform: uppercase;
        }
      }
    hr{
      width: 80%;
      margin: 0 auto;
    }

    
    .primary-btn-contain{
      width: 90%;
      margin: 3rem auto;
  
      .primary-button{
        width: 15%;
        margin: 0.5rem;
        margin-left: auto;
        padding: 1rem;
        justify-content: center;
        border-radius: 1rem;
        border: none;
        background: #BF0A30;
        color: #ffffff;
        font-size: clamp(16px, 1vw, 2rem);
        display: flex;
    }
    }
  }

}