@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body{
  margin: 0 !important;
  font-family: "Roboto", sans-serif !important;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 100;
  background: transparent;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 3%;
  font-size: clamp(1rem, 1vw + 1rem, 3rem);
  text-transform: uppercase;

  .logo{
    width: 20%;
    a{
      display: block;
      img{
        width: 80%;
        height: auto;
      }
    }
  }

  .hamburger {
    display: none; // Hide hamburger by default
    cursor: pointer;
  }

  .links {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border: 1px solid rgba(255, 255, 255, 0.4);
    font-size: clamp(16px, 0.7vw, 3rem);
    backdrop-filter: blur(5px);
    border-radius: 2rem;
    color: #ffffff;
    padding: 1.3rem;

    .dropdown {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: inherit;

        a {
            color: inherit; // Use inherit to maintain consistency
            text-decoration: none; // Optional: Add if you want to remove underline
        }

        button {
            width: 100%;
            font-size: inherit;
            background: transparent;
            border: none;
            color: inherit;
            text-transform: inherit;
            cursor: pointer; // Optional: Add cursor style for better UX
        }
      }
  }

 .donate {
  width: 10%;

  button {
    width: 100%;
    color: #ffffff;
    padding: 1rem;
    background: #BF0A30;
    border-radius: 10px;
    font-size: clamp(16px, 1vw, 3rem);
    border: none;
    cursor: pointer; // Added cursor for better UX
    transition: background 0.3s; // Added transition for hover effect

    &:hover {
      background: darken(#BF0A30, 10%); // Darken button on hover
    }
  }
}

a {
  color: #ffffff;
  text-decoration: none;
  display: block;
}

  .dropdown {
    &-content {
      width: 10rem;
    }

    &-item {
      text-decoration: underline;
      text-align: center;
      position: relative; // Required for absolute positioning of the separator
      padding: 1rem 0; // Add padding for spacing

      // Separator style
      &::after {
        content: '';
        display: block;
        width: 80%;
        height: 1px; // Thickness of the line
        background-color: #ffffff; // Color of the line
        margin: 0.1rem auto; // Spacing around the line
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0; // Position it at the bottom of the item
        opacity: 0.5; // Optional: make the line semi-transparent
      }

      // Remove the separator for the last item
      &:last-child::after {
        content: none;
      }
    }

    &-content-transition {
      &-enter {
        opacity: 0;
        margin-left: -100%;
      }

      &-enter-active {
        opacity: 1;
        margin-left: 0%;
        transition: opacity 500ms, margin-left 500ms;
      }

      &-exit {
        opacity: 1;
        margin-left: 0%;
      }

      &-exit-active {
        opacity: 0;
        margin-left: -100%;
        transition: opacity 500ms, margin-left 500ms;
      }
    }
  }
}

@media (max-width: 768px) { // Adjust based on your needs
  .navbar{
    justify-content: space-between;
    .logo{
      width: 20rem;
      max-width: 60%; // Adjust the logo size
      height: auto;
    }
    .donate{
      display: none;
    }

      .links {
        display: none; // Hide links by default on mobile
        flex-direction: column;
        width: 100%;
      }

      .hamburger {
        display: block; // Show hamburger on mobile
        width: 20%;
        color: #ffffff;
      }
    }
  }


//Extra styling mate
.navbar .navlink {
  padding: 10px;
}

.navbar .navlink.active,
.navbar .dropdown-header:hover .dropdown-content {
  color: #000;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: block;
  position: absolute;
  background: #333;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  top: 205%;
  left: 0;
}

.dropdown-item {
  padding: 10px;
}

.dropdown-header:hover .dropdown-content {
  display: block;
}