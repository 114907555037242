.dropdown-header {
  display: flex;
  align-items: center;
  position: relative;
}

.dropdown-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #ffffff; /* Change color as needed */
  margin-left: 8px; /* Space between text and arrow */
  transition: transform 0.3s ease;
}

.dropdown-arrow.open {
  transform: rotate(180deg); /* Rotate the arrow when dropdown is open */
}

.dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-item .dropdown-arrow {
  margin-left: auto; /* Push the arrow to the right */
}